import React from "react";
import { Link } from "gatsby";

import styles from "./button.module.css";

console.log(styles);

class Button extends React.Component {
  render() {
    return (
      <div id={styles.button}>
        <Link to={this.props.link}>
          <div id={styles.container}>
            <img src={this.props.img} alt=""/>
            <h3>{this.props.flavorText}</h3>
          </div>
        </Link>
      </div>
    );
  }
}

export default Button;
