import React from "react";
import { StaticQuery, graphql } from "gatsby";

import ButtonRow from "../components/button_row";
import Button from "../components/button";
import Layout from "../components/layout";

import tools from "../images/tools.svg";
import shakingHands from "../images/shaking_hands.svg";
import handsHeart from "../images/hands_heart.svg";
import home from "../images/home.svg";
import letter from "../images/letter.svg";

const query = graphql
`
query orodjarna {
  allMarkdownRemark(filter: {frontmatter: {path: {eq: "/orodjarna"}}}) {
    edges {
      node {
        id
        frontmatter {
          path
          navTitle
        }
        excerpt
        html
        rawMarkdownBody
      }
    }
  }
}
`;

const OrodjarnaPage = props => (
  <Layout navTitle={props.data.frontmatter.navTitle}>
    <div dangerouslySetInnerHTML={{ __html: props.data.html }}/>
    <ButtonRow>
      <Button
        img={home}
        flavorText="Najemnina?"
        link="/orodjarna/najemnina"
      />

      <Button
        img={tools}
        flavorText="Delovno mesto?"
        link="/orodjarna/delo"
      />

      <Button
        img={shakingHands}
        flavorText="Rabim pomoč."
        link="/orodjarna/rabim"
      />

      <Button
        img={handsHeart}
        flavorText="Nudim pomoč."
        link="/orodjarna/nudim"
      />

      <Button
        img={letter}
        flavorText="Pisma"
        link="/orodjarna/pisma"
      />
    </ButtonRow>
  </Layout>
);

export default () => (
  <StaticQuery
    query={query}
    render={ data => (
      <OrodjarnaPage data={data.allMarkdownRemark.edges[0].node}/>
    )}
  />
);
