import React from "react";

import styles from "./button_row.module.css";

console.log(styles);

export default props => (
  <div id={styles.buttonRow}>
    {props.children}
  </div>
);
